import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { ModelAutocompleteField } from '@front/squirtle/'

import RateParameterSection from '@abra/pages/RateParameter/Details/Section/Primary'

const Row1 = ({
  companycontract,
  applicable_load,
  handleLoadChange,
  isTaxicounter,
  ...props
}, context) => {

  return (
    <Grid container item xs={12} spacing={1} >
      <Grid item xs={4}>
        <ModelAutocompleteField
          name='rateparameter.rateparameter_id'
          label={I18n.t('name.label.one')}
          labelKeys={['name']}
          model_name={'rateparameter'}
          searchMode={'search'}
          searchQuery={'startsWith'}
          autocompleteKey={'name'}
          selectFist={false}
          loadOnMount
        />
      </Grid>
    </Grid>
  )
}

const PrimarySection = ({ formattedSuspension, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('rateparameter.label.other')} </Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={2}>
        <Row1 />
        <RateParameterSection disabled_name disabled />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
