import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Typography, Box, StepLabel, StepContent } from '@mui/material'

import { SingleOptionSelectorField, MenuSelectorField, ModelAutocompleteField } from '@front/squirtle/'

const Row1 = ({ isCeiling, ...props }) =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs>
      <ModelAutocompleteField
        name={'start_zone'}
        label={I18n.t('address.source')}
        model_name={'zone'}
        labelKeys={['name']}
        searchMode={'search'}
        searchQuery={'startsWith'}
        autocompleteKey={'name'}
        selectFirst={false}
        loadOnMount
        required
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        name={'end_zone'}
        label={I18n.t('address.destination')}
        model_name={'zone'}
        labelKeys={['name']}
        searchMode={'search'}
        searchQuery={'startsWith'}
        autocompleteKey={'name'}
        selectFirst={false}
        loadOnMount
        required
      />
    </Grid>
    <Grid item xs hidden={isCeiling}>
      <MenuSelectorField
        name={'distance_algo'}
        label={I18n.t('distance.algo.label')}
        options={[
          { label: I18n.t('distance.algo.none'), value: null },
          { label: I18n.t('distance.algo.source'), value: 'source' },
          { label: I18n.t('distance.algo.destination'), value: 'destination' },
          { label: I18n.t('distance.algo.full'), value: 'full' }
        ]}
        required
      />
    </Grid>
  </Grid>

const Row2 = ({ isCeiling, ...props }) =>
  <Grid container item xs={6} spacing={1} direction='row' alignItems='center'>
    <Grid item xs hidden={isCeiling}>
      <SingleOptionSelectorField
        name={'individual_pricing'}
        optionLabel={I18n.t('pricerule.individual_pricing')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'two_way'}
        optionLabel={I18n.t('transport.two_way')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='distance_strategy'
        label={I18n.t('distance.strategy.label')}
        options={[
          { label: I18n.t('distance.strategy.basic'), value: 'basic' },
          { label: I18n.t('distance.strategy.google'), value: 'google' },
        ]}
        required
      />
    </Grid>
  </Grid>

const GeoSection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('zone.associated')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ borderRadius: 3, boxShadow: 6, p: 2, mb: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
        <Row2 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(GeoSection)
